import React from "react"
import BlogRoll from "../components/shubhamGautamRoll"
import NavigationBar from "../components/Navbar"
import { Helmet } from "react-helmet"
import loadable from "@loadable/component"

const Scroll = loadable(() => import("../components/scroll"))
const Footer = loadable(() => import("../components/footer3"))

export default class ShubhamGautamPage extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Helmet
          htmlAttributes={{
            lang: "en",
          }}
        >
          <meta charSet="utf-8" />
          <title>Shubham Gautam </title>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta
            name="description"
            content="Learn well-designed DSA, system design and OOP content written by Shubham Gautam on EnjoyAlgorithms."
          />
        </Helmet>
        <div className="sm:sticky top-0 z-50">
          <NavigationBar />
        </div>

        <div className="bg-light-white">
          <div className="container bg-light-white mx-auto px-6 lg:px-8 xl:px-10 pt-5 md:pt-8 lg:pt-10">
            <div className="md:grid md:grid-cols-12">
              <div className="md:col-span-11 md:text-left md:text-left">
                <h1 className="tracking-wider text-xl lg:text-2xl xl:text-3xl text-gray-900 text-center md:text-left">
                  Hi, I am Shubham Gautam
                </h1>

                <div className="text-left tracking-wider text-sm xl:text-base 2xl:text-lg text-gray-600 text-left md:text-left">
                  <p className="mt-3">
                    Founder and CEO EnjoyAlgorithms | IIT | Super 30 | Educator
                    | Passionate about solving problems using data structures
                    and algorithms, system design, object-oriented design, and
                    mathematics | Other skills and interests: C, C++, Java,
                    Python, JavaScript, HTML, tailwind CSS, React, Gatsby,
                    NodeJs, embedded systems, machine learning, UX design,
                    product growth, curriculum design, public speaking.
                  </p>

                  <p className="mt-3">
                    Nothing brings me greater joy than guiding tech
                    professionals in mastering computer science and helping them
                    achieve their dream success. I am on a mission to simplify
                    computer science concepts for various learners and empower
                    them to become confident problem solvers. My guiding
                    principles are: trust yourself, think outside the box,
                    practice flexibility, explore solutions using multiple
                    methods, cultivate patience, embrace failure as a learning
                    opportunity, collaborate, and exchange ideas.
                  </p>

                  <p className="mt-3">Email: shubham@enjoyalgorithms.com</p>
                  <p className="mt-3"></p>
                </div>
                <div className="flex justify-end">
                  <a
                    href="https://www.linkedin.com/in/shubhamgautamsuper30/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex py-3 underline hover:text-new-blue"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-external-link"
                    >
                      <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                      <polyline points="15 3 21 3 21 9"></polyline>
                      <line x1="10" y1="14" x2="21" y2="3"></line>
                    </svg>
                    <p className="ml-2 tracking-wider text-base xl:text-lg">
                      Linkedin
                    </p>
                  </a>
                  <a
                    href="https://shubhamgautamsuper30.medium.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex ml-4 py-3 underline hover:text-new-blue"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-external-link"
                    >
                      <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                      <polyline points="15 3 21 3 21 9"></polyline>
                      <line x1="10" y1="14" x2="21" y2="3"></line>
                    </svg>
                    <p className="ml-2 tracking-wider text-base xl:text-lg">
                      Medium
                    </p>
                  </a>
                </div>
              </div>
            </div>

            <h1 className="tracking-wider text-xl lg:text-2xl 2xl:text-3xl mt-12 text-center text-gray-900">
              <span className="">Shubham Gautam Blogs</span>
            </h1>

            <section>
              <div className="content mt-8">
                <BlogRoll />
              </div>
            </section>

            <Scroll showBelow={250} />
            <Footer />
          </div>
        </div>
      </React.Fragment>
    )
  }
}
